.Header {
  @include title;

  position: fixed;
  z-index: $z-index-header;

  width: auto;

  top: 0;
  left: 0;

  text-transform: uppercase;

  @include breakpoint("small") {
    margin: px(30) px(40);
  }
  @include breakpoint-max("small") {
    width: 100%;
    background: $white;
    padding: 10px 25px;
    bottom: 0;
    top: auto;
  }

  ul {
    @include breakpoint-max("small") {
      display: flex;
      justify-content: space-between;
    }
  }
  li {
    display: flex;
  }
  &__link {
    text-decoration: line-through;

    &.is-active {
      text-decoration: none;
    }

    @include hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
}
