/*
* COLORS
*/

$black: #000;
$white: #fff;
$red: #ff0000;

$background: $white;
$text: $black;
