.Images {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-images;

  &.is-hidden {
    pointer-events: none;
    opacity: 0;
  }

  &__inner {
    position: absolute;
    width: 100vw;
    height: 100vw;
    top: 0;
    left: 0;
    display: block;

    will-change: transform;
    transform-style: preserve-3d;

    transform-origin: 50vw 50vh;
    z-index: 1;
  }
  &__item {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  &__item-inner {
    border: 1px solid $black;
    background: $black;
    pointer-events: all;
    cursor: pointer;
    .is-hidden & {
      pointer-events: none;
    }
  }
}

.ImagesCursor {
  width: 0px;
  height: 0px;
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 99999;
  pointer-events: none;
  &__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    white-space: nowrap;
  }
}
.ImagesActive {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  &__window {
    position: fixed;
    top: 40px;
    left: 40px;
    bottom: 40px;
    right: 40px;
    pointer-events: none;
  }
  &__cursor {
    position: fixed;
    top: 0;
    left: 20px;
    @include title;
    z-index: 99;
    & > div {
      position: absolute;
      top: 0;
      left: 0;
    }

    &.is-touch {
      position: fixed;
      width: 100%;
      padding: 10px 25px;
      background: $white;
      left: 0;
      text-align: center;
      & > div {
        position: relative;
        display: inline;
        left: auto;
        top: auto;
      }
    }
  }
  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $black;
    border: 1px solid $black;
    // &>div{
    // }
  }
  &__back {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
