.video {
  width: 100%;
  padding-bottom: 50%;
  display: block;
  position: relative;
  overflow: hidden;

  &__src {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;

    opacity: 1;

    video {
      object-fit: cover;
    }
  }
}
