// @include font(font-family, font-weight, font-size, line-height, letter-spacing)
// all values according to 1500px photoshop file.
// returns viewport font-sizes and em line-height.

body {
  @include text;
  font-feature-settings: 'ss01';
  font-feature-settings: 'ss02';
  -moz-font-feature-settings: 'ss02', 'ss01' !important;
  -webkit-font-feature-settings: 'ss01', 'ss02' !important;
}
input,
label,
textarea {
  @include text;
}

p {
}

h1,
h2,
h3,
h4,
h5 {
  @include title;

  b,
  strong {
    font-weight: 400;
  }
}
a {
  position: relative;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
