/* -----
Typography, WebFonts
----- */
$font-family: "Helvetica", Arial, sans-serif;

/* -----
Heading mixins

! Use the minimum needed, otherwise include it with if statements !
------ */

@mixin title($with-breakpoints: true) {
  @include fontPX(
    $family: $font-family,
    $weight: 400,
    $size: 14,
    $line: 18,
    $spacing: 1
  );

  @if $with-breakpoints {
    @include breakpoint("small") {
      @include font(
        $family: $font-family,
        $weight: 400,
        $size: 20,
        $line: 26,
        $spacing: 1
      );
    }
  }
}

@mixin text($with-breakpoints: true) {
  @include fontPX(
    $family: $font-family,
    $weight: 400,
    $size: 16,
    $line: 20,
    $spacing: 1
  );

  @if $with-breakpoints {
    @include breakpoint("small") {
      @include font(
        $family: $font-family,
        $weight: 400,
        $size: 40,
        $line: 56,
        $spacing: 1
      );
    }
  }
}
