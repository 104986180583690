.Back {
  z-index: $z-index-back;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__img {
    position: absolute;
    top: 0;
    left: 30%;
    width: 40%;
    height: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;

    &.is-active {
      opacity: 1;
    }
  }
}

.Layout {
  @include breakpoint-max("small") {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

main {
  z-index: $z-index-main;
  @include text;
  padding: 25px;

  @include breakpoint("small") {
    padding: px(25);
    padding-left: px(235);
  }
  @include breakpoint-max("small") {
    padding-bottom: 75px;
  }
}

.Page {
  width: 100%;
  @include breakpoint("small") {
    width: 76.5%;
  }
}
